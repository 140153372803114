@import '../../../../shared/styles/main.scss';


.store-categories-table {

    &__panel-container {
        min-height: 400px;
        // max-height: 400px;
    }

    &__image {
        width: 80px;
        height: 80px;
      }
    

}