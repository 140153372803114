@import '../../shared/styles/main.scss';
.categories {
    &__wrapper {
        height: 100%;
        min-height: 100vh;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;

        &__left-menu-item {
            display: flex;
            flex-direction: column;
            & label {
                // margin-bottom: 0.5rem;
                font-weight: normal;
                color: var(--accent);
            }
        }
    }
}