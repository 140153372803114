@import "../../shared/styles/main.scss";
.category-details {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    animation: fadeIn 1s;
    min-height: 100vh;
  }
  &__count {
    color: var(--accent);
    font-size: 1rem;
    margin-left: 0.5rem;
  }
  &__menu {
    // padding-top: 1rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;

    & svg {
      fill: $primaryDark;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    // padding: 0.5rem;
  }

  &__pp-wrapper {
    background-color: #fff;
    box-shadow: $shadow;
    margin-bottom: 1.5rem;
    border-radius: 4.992px;
  }

  &__category-info {
    &__wrapper {
      flex: 1;
      padding: 1.5rem;
      padding-top: 0;
      display: flex;
    }

    &__image {
      max-width: 150px;
      max-height: 300px;
      padding: 0.2rem;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-right: 0;
        padding-bottom: 0;
        &[dir="rtl"] {
          padding-right: 1rem;
          padding-left: 0;
        }
        flex: 1;
    }
    &__description {
        margin-top: 0.5rem;
        & p {
            margin: 0;
        }
    }
  }

  &__sub-categories {
    &__wrapper {
      padding: 0.5rem;
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        &--left {
            color: var(--accent);
            font-size: 1rem;
        }

        &--right {
          // display: flex;
          // align-items: center;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
    }
  }
}
