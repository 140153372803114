@import '../../shared/styles/main.scss';


.geofence {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__add-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // height: 500px;
    }

    &__form-container {
        flex: 1;
        // background-color: #fff;
        // margin-right: 1rem;
        padding: 2rem;
        // border: 1px solid $borderColor;
    }

    &__map-container {
        // flex: 2;
        height: 60vh;
    }
}