@import '../../shared/styles/main.scss';

.role-management__users {
    &__menu-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem 0.5rem;
    }

    &__left-menu-item {
        label {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    &__right-menu-item {
        
    }

    &__table-actions {
        display: flex;
        justify-content: center;
    }

    &__action-icon {
        margin-right: 1rem;
        height: 18px;
        &:hover {
            cursor: pointer;
        }
        & svg {
            fill: $primaryDark;
        }
        &:last-of-type {
            margin-right: 0;
        }

        &--hidden {
            visibility: hidden;
        }
    }
}