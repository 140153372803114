@import '../../../../shared/styles/main.scss';
.categories__category-list__card {
    &__wrapper {
        width: 12rem;
        background-color: #fff;
        padding: 0.2rem;
        border-radius: 4px;
        margin: 2rem;
        margin-left: 0;
        // margin-right: 1rem;
        margin-top: 0;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        display: flex;
        flex-direction: column;
        // animation: fadeIn 0.4s ease-in;
    }

    &__shadow {
        // box-shadow: $shadow;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        animation: fadeIn 0.3s;
        align-self: flex-start;
        & code {
            color: var(--accent);
        }
        &.disabled {
            // pointer-events: none;
            cursor: not-allowed;
        }
    }

    &__image-container {
        flex: 1;
        & img {
            width: 100%;
            height: 100%;
            max-height: 200px;
        }
    }

    &__footer{
        display: flex;
        justify-content: space-between;
        padding:0.2rem;
        padding-top: 0.5rem;

        &__right {
            margin-left: 0.5rem;
            & svg {
                fill: $primaryDark;
            }
        }
    }
}

.cat-card-custom-add {
    background-color: var(--accent);
    & svg {
        fill: #fff;
    }
    &.disabled {
        pointer-events: none;
        // cursor: pointer;
    }
}