@import '../../shared/styles/main.scss';

.edit-order {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }


    &__order-total-wrapper {
        display: flex;
        flex: 1;
        &>div {
            margin-right: 2rem;
            font-size: 1.2rem;
            // font-weight: bold;
            &>span {
                color: $accent;
                font-weight: bold;
            }
        }
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        align-items: center;

        &-action {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__table-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__table-menu {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
        margin-right: 1.5rem;
    }

}