@import '../../../../shared/styles/main.scss';
.geofence-table {
    &__table-actions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__action-icon {
        margin-right: 1rem;
        height: 18px;
        &:hover {
            cursor: pointer;
        }
        & svg {
            fill: $primaryDark;
        }
        &:last-of-type {
            margin-right: 0;
        }

        &--hidden {
            visibility: hidden;
        }
    }
}