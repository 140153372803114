@import '../../../shared/styles/main.scss';
.pagination-info {
    &__wrapper {
        color: $textSecondary;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
    }

    &__info {
        color: $accent;
    }
}