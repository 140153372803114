@import "../../shared/styles/main.scss";

.oyelabs__images-upload {
  &__menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0.5rem;
  }
  &__uploader {
    display: flex;
    justify-content: center;

    & .ant-upload.ant-upload-drag {
      padding: 1rem 2rem;
    }
  }
  &__content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 1rem;
  }
  
  &__upper-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;

    &__left {
      display: flex;
      flex-direction: column;
    }

    &__dropdown {
      margin-left: 1rem;
      & label {
        color: var(--accent);
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
    &__right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__records-per-page {
      & label {
        color: var(--accent);
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
  }
}
