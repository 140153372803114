@import "../../shared/styles/main.scss";

.brands {
  &__menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0.5rem;
  }

  &__upper-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;

    &__left {
      display: flex;
    }

    &__dropdown {
      margin-left: 1rem;
      & label {
        color: $accent;
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
    &__right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__records-per-page {
      & label {
        color: $accent;
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
  }
}
