@import '../../../../shared/styles/main.scss';

.stores-modal {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__form-container {
        flex: 2;
        display: flex;
        flex-direction: column;
        padding-right: 1rem;
    }

    &__image-container {
        display: flex;
        flex-wrap: wrap;
        &__item {
            flex: 1;
            & img {
                max-width: 100%;
                max-height: 200px;
            }

            padding-right: 1rem;
            &:last-of-type{
                padding-right: 0;
            }
        }
    }
    
    &__category-container {
        display: flex;
        flex-wrap: wrap;
        // padding: 0.5rem 0;
        border-bottom: 1px solid $borderColor;
    }

    &__category-tag {
        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 500px;
            background-color: $primary;
            padding: 0.3rem 0.8rem;
            min-width: 30px;
            margin: 0.25rem;
        }

        &__text {
            color: #fff;
            margin-right: 1rem;
        }

        &__rank {
            margin-right: 4px;
        }

        &__icon-container {
            display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    min-width: 34px;
    & svg {
        fill: #fff;
    }
        }
    }

    &__google-map {
        height: 400px;
        width: 100%;
    }
}