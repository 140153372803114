@import '../../../../shared/styles/main.scss';

.oyelabs__images-upload__image-card {
    &__wrapper {
        width: 250px;
        // height: 250px;
        margin: 0.7rem;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid $borderColor;
        // box-shadow: $shadowLow;
        display: flex;
        flex-direction: column;
    }
    
    &__content {
        padding: 1rem;
    }

    &__footer {
        border-top: 1px solid $highlight;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
    }

    &__image {
        height: 180px;
        border-bottom: 1px solid $highlight;
        & img {
            width: 100%;
            height: 100%;
        }
    }
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2)) !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%) !important;
    background-size: 400% 100% !important;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite !important;
    animation: ant-skeleton-loading 1.4s ease infinite !important;
}