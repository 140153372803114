@import "../../shared/styles/main.scss";

.schedule {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    flex-wrap: wrap;
    &--left {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
