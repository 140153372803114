@import '../../../../shared/styles/main.scss';
.categories__add-edit-category-modal {
    &__image-container {
        display: flex;
        position: relative;
        padding-bottom: 1.5rem;

        &__image {
            & img {
                width: 100%;
                max-height: 12rem;
            }
    
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-right: 1rem;
            &[dir="rtl"] {
                padding-right: 0;
                padding-left: 1rem;
            }

        }

        &__form {
            flex: 1;
        }
    }

    &__span-imp{
        color: #ff0000;
    }


}