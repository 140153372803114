@import "../../../shared/styles/main.scss";

.custom-upload-button {
    &__input {
        display: none;
    }

    &__label {
        text-align: center;
    background-color: $primary;
    color: #fff !important;
    transition: all 0.2s;
    cursor: pointer;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    &:hover {
        background-color: $primaryDark;
    }
    }
}