@import '../../shared/styles/main.scss';

.customers {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        align-items: center;

        &__records-per-page {
            & label {
                color: var(--accent);
                font-weight: normal;
                margin-right: 0.5rem;
            }
        }
        
    }

}