@import "../../../../shared/styles/main.scss";

.parts__add-edit-part-modal {
  &__image-container {
    display: flex;
    position: relative;
    padding-bottom: 1.5rem;
    justify-content: space-evenly;
    &__image {
      & img {
        width: 100%;
        max-height: 12rem;
      }

      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 1rem;
    }

    &__form {
      flex: 1;
    }
  }
}
.custom-table {
  & .ant-table-thead {
      & .ant-table-cell {
          background-color: #f3f2f7;
          border-bottom: 2px solid #ebe9f1;
  border-top: 1px solid #ebe9f1;
  text-align: center;
      }
  }

  & .ant-table-tbody {
      & .ant-table-cell {
          text-align: center;
          // padding: 0.72rem 2rem !important;
          font-size: 14px;
      }
  }

  & .ant-table-pagination.ant-pagination {
      margin-right: 16px;
  }
}