@import "../../../../shared/styles/main.scss";

.brands {
  &__image {
    width: 80px;
    height: 80px;
  }

  &__table-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__table-container {
    min-height: 400px;
  }

  &__sticky-right {
    position: sticky;
    right: 0;
    box-shadow: $shadowLow;
  }

  &__action-icon {
    margin-right: 1rem;
    height: 18px;
    &:hover {
      cursor: pointer;
    }
    & svg {
      fill: $primaryDark;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &--hidden {
      visibility: hidden;
    }
  }
}
