@import '../../shared/styles/main.scss';

.terms-and-conditions {
    &__wrapper {
        display: flex;
        min-height: -webkit-fill-available;
        // height: 100vh;
        padding-bottom: 4rem;
        // height: 100%;
        // overflow: scroll;
        // justify-content: center;
        // align-items: center;
//         /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f0f9ff+0,cbebff+47,a1dbff+100;Blue+3D+%2313 */
// background: #f0f9ff; /* Old browsers */
// background: -moz-linear-gradient(45deg,  #f0f9ff 0%, #cbebff 47%, #a1dbff 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(45deg,  #f0f9ff 0%,#cbebff 47%,#a1dbff 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(45deg,  #f0f9ff 0%,#cbebff 47%,#a1dbff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f9ff', endColorstr='#a1dbff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
// background: transparent url('../../assets/images/auth-background.png') center center fixed;
        // background: transparent url('../../assets/images/bg.png') center center fixed;
        background-color: rgba(38, 113, 225, 0.46);
        // background: linear-gradient(180deg, #FFFFFF 0%, rgba(238, 245, 255, 0.31) 19.64%, rgba(209, 225, 249, 0.4) 50.31%, rgba(38, 113, 225, 0.46) 100%);
        background-size: cover;
    }

    &__content {
        width: 80%;
        margin: 0 auto;
        padding: 2rem;
        margin-top: 2rem;
        background-color: #fff;
        box-shadow: $shadowLow;
        border-radius: 2rem;
         & img {
             height: 100px;
         }
    }

    &__header {
        padding: 1rem;
        text-align: center;
    }
}