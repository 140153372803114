
.forget-password__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    /* height: 100%; */
}

.forget-password__wrapper h2 {
    font-weight: normal;
}

.form-control {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.2rem;
    padding-bottom: 1.5rem;
    position: relative;
    margin-top: 1rem;
}

.form-control label {
    color: #313131;
    font-size: 0.9rem;
}
.error-message {
    position: absolute;
    bottom: 7px;
    color: red;
    font-size: 0.8rem;
}

.form-control input {
    padding: 0.5rem;
    border: 0;
    border-bottom: 1px solid grey;
    outline: none;
}

.sweet-loading {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 20%;
    z-index: 999;
}

.forget-password__container {
    width: 100%;
    display: flex;
    background-color: white;
    /* justify-content: center; */
    max-width: 500px;
    margin: 1rem;
    border-radius: 0.5rem;
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
    0 2px 4px rgba(0,0,0,0.07), 
    0 4px 8px rgba(0,0,0,0.07), 
    0 8px 16px rgba(0,0,0,0.07),
    0 16px 32px rgba(0,0,0,0.07), 
    0 32px 64px rgba(0,0,0,0.07);
}
.submit {
    outline: none;
    border: 0;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 100px;
    background-color: var(--accent);
    color: white;
    margin: 1rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
}

.forget-password__logo {
    width: 100%;
    // max-height: 150px; 
    height: 20rem;
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
}
.forget-password__logo>img {
    width: 100%;
    /* height: 100%; */
}
