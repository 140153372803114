@import "../../../../shared/styles/main.scss";


.order-details-modal {
    &__wrapper {
        padding: 1rem;
    }

    &__table-wrapper {
        min-height: 200px;
    }

    &__booking-info {
        &__container {
            display: flex;
            flex-direction: column;
        }

        &__item {
            display: flex;
            justify-content: space-between;
        }
    }

    &__billing {
        &__container {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
        }

        &__item {
            display: flex;
            justify-content: space-between;
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            padding-top: 0.5rem;
            padding-bottom: 0.2rem;
            border-top: 2px solid black;
            border-bottom: 2px dotted black;
        }
    }
}