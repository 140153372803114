@import "../../../shared/styles/main.scss";
.header {
  &__wrapper {
    // padding: 1rem;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    // font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;

    // font-feature-settings: "tnum","tnum";
    position: relative;
    // position: sticky;
    // top: 0;
    padding: 28px 2rem;
    padding-top: 0;
    // padding-top: 0;
    background-color: transparent;
    width: 100%;

    & section {
      // padding-bottom: 0.5rem;
    }
  }

  &__back-button {
    & svg {
      fill: #636363;
      margin-right: 1rem;
    }
  }

  &__crumbs {
    font-size: 14px !important;
  }

  &__title {
    margin: 0;
    font-weight: normal;
    color: #000;
    // margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    & header {
      font-weight: 600;
      padding-right: 1rem;
      border-right: 1px solid #d6dce1;
      line-height: 28.795px;
      color: #636363;
      font-size: 23.5px;
    }
    & section {
      padding-left: 1rem;
    }
    // justify-content: space-between;
  }

  &__title[dir="rtl"] {
    & header {
      padding-right: 0;
      padding-left: 1rem;
      border-left: 1px solid #d6dce1;
      border-right: none;
    }

    & section {
      padding-right: 1rem;
      padding-left: 0;
    }
  }

  &__sub-title {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    color: var(--accent);
  }

  &__cta {
    color: var(--accent) !important;
  }
}
