@import '../../../../shared/styles/main.scss';

.content-management__editor {
    &__wrapper {
        // margin-top: 1rem;
        & div.rdw-editor-wrapper {
            background-color: #fff;
        }
        &>div {
            // box-shadow: $shadowLow;
        }
    }
    &__text {
        // background-color: var(--primary);
        padding: 1rem;
        padding-top: 0;
        height: 46.9vh;
        overflow-y: scroll;
    }

    &__action {
        border-top:1px solid #efefef;
        display: flex;
        padding: 1rem;
        justify-content: flex-end;
    }

    &__toolbar {
        background-color: #fff;
    }
}