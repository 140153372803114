
.certificate__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    /* height: 100%; */
}
.certificate__heading {
    & h2 {
        text-transform: uppercase;
        font-family: sans-serif;
        font-weight: 300;
        font-size: 40px;
        line-height: 52px;
        color: #252529;
    }

    & label {
        font-weight: 700;
        font-family: sans-serif;
        font-size: 19px;
        text-transform: uppercase;
        color: #4A4A4A;
    }
}
.certificate__name {
    display: block;
    &__name{
        font-family: sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 29.2361px;
        line-height: 34px;
        /* identical to box height, or 118% */
        // display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        /* dark */
        color: #252529;
    }
    &__text {
        font-family: sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13.7582px;
        line-height: 26px;
        /* identical to box height, or 187% */

        // display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        /* dark gery */

        color: #4A4A4A;

    }
    & p {
        margin-top: 10px;
        color: #4A4A4A;
        font-size: 18px;
        line-height: 26px;
    }
    & label {
        margin-top: 10px;
    }
    & h3 {
        margin-top: 10px;
        font-family: sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 21.4971px;
        line-height: 34px;
        /* identical to box height, or 160% */

        // display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;

        /* dark */

        color: #252529;
    }
    
}
.certificate__wrapper h2 {
    // font-weight: 500;
}

.certificate__logo-image {
    height: 100px;
}

.certificate__footer {
    display: flex;
    justify-content: space-around;

    &__sign {
        display: flex;
        flex-direction: column;
        & h3 {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 17.1977px;
            line-height: 26px;
            /* identical to box height, or 150% */

            // display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.03em;

            /* dark */

            color: #252529;
        }

        & label {
            font-family: sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12.0384px;
            line-height: 17px;
            /* or 143% */

            // display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.03em;

            /* theme */

            color: #2671E1;
        }

        & span {

            font-family: sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12.0384px;
            line-height: 17px;
            /* or 143% */

            // display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.03em;

            /* theme */

            color: #2671E1;
        }
    }
    
    &__detail{
        display: flex;
        flex-direction: column;
        & label {
            margin-top: 15px;
            font-family: sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12.0384px;
            line-height: 17px;
            text-transform: uppercase;
            /* identical to box height, or 143% */

            // display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* light grey */

            color: #828282;
        }
    }
}

.sweet-loading {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 20%;
    z-index: 999;
}

.certificate__container {
    width: 100%;
    border: 9.46px solid;
    border-image-source: linear-gradient(180deg, rgba(38, 113, 225, 0.43) 0%, rgba(209, 225, 249, 0.4) 34.9%, rgba(200, 219, 247, 0.431526) 47.4%, rgba(193, 214, 246, 0.45656) 60.94%, rgba(190, 212, 246, 0.4672) 77.08%, rgba(38, 113, 225, 0.55) 100%);
    display: flex;
    background-color: white;
    /* justify-content: center; */
    max-width: 500px;
    margin: 1rem;
    // border-radius: 0.5rem;
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
    // box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
    // 0 2px 4px rgba(0,0,0,0.07), 
    // 0 4px 8px rgba(0,0,0,0.07), 
    // 0 8px 16px rgba(0,0,0,0.07),
    // 0 16px 32px rgba(0,0,0,0.07), 
    // 0 32px 64px rgba(0,0,0,0.07);
}

.certificate__logo {
    width: 100%;
    /* max-height: 150px; */
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
}
.certificate__logo>img {
    // width: 100%;
    /* height: 100%; */
}