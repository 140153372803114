@import '../../shared/styles/main.scss';

.cancellation-reasons {
    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__content {

    }

    &__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &--left {
            display: flex;
        }  

        &--right {

        }
    }

}