@import '../../shared/styles/main.scss';
.geofence-edit {
    &__wrapper {
        width: 100%;
        height: 100%;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__map-container {
        // flex: 1;
        height: 59vh;
    }

    &__form-container {
        // flex: 1;
        // margin-top: 1rem;
        // padding: 1rem;
        // background-color: #fff;
        // border: 1px solid $borderColor;
    }
}