@import "../../../shared/styles/colors.scss";

.addagntbtn {
  border: 1px solid $accent;
  color: $accent;
  margin: 5px;
  padding: 8px 24px;
  border-radius: 0.3rem;
  background: #fff;
  &:last-child {
    background: $accent;
    color: white;
  }
}
.icon-row {
  // .icon-col {
  //     margin-right: 1rem;
  // }
  .circle_icon {
    height: 60px;
    width: 60px;
    background: $background;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 100px;
    svg {
      fill: $sidebarText;
      &:hover {
        fill: $accent;
        transition: 300ms ease-in-out;
      }
    }
  }
}
.ciclebtn {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: palegreen;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.addAgent {
  &__form-container {
    margin-top: 0.5rem;
  }
  &__image-container {
    img {
      height: 100px;
      width: 100px;

    }
  }
}
