@import "../../../../shared/styles/main.scss";

.dashboard__yearly-chart {
    &__wrapper {
            
    }

    &__header {
        padding: 1.5rem;
        padding-top: 0;
        text-align: left;
        font-size: 1.1rem;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 1.5rem;
    }

    &__container-class {
        overflow: hidden;
        background-color: #fff;
        padding: 1.5rem;
        box-shadow: $shadow;
        border-radius: 0.428rem;
        margin-bottom: 28px;
        // border: 1px solid $borderColor;
        // padding: 0.5rem;
        // padding-right: 3rem;
        // margin-right: 1rem;
        // margin-bottom: 1rem;
        
    }
}