@import "../../../../shared/styles/main.scss";


.cancel-order-modal {
    &__wrapper {
        padding: 1rem;
    }

    &__table-wrapper {
        min-height: 200px;
    }

    &__booking-info {
        &__container {
            display: flex;
            flex-direction: column;
        }

        &__item {
            display: flex;
            justify-content: space-between;
        }
    }

    &__billing {
        &__container {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
        }

        &__item {
            display: flex;
            justify-content: space-between;
        }

    }

    &__footer {
        margin-top: 2rem;
    }
}