@import '../../../../shared/styles/main.scss';

.content-management__menu {
    &__wrapper {
    }

    &__item {
        padding: 0.8rem 1rem;
        display: flex;
        justify-content: space-between;
        // min-width: 8rem;
        align-items: center;
        background-color: #fff;
        border: 1px solid $borderColor;
        margin: 0.5rem;
        margin-left: 0;
        margin-top: 0;
        font-weight: bold;
        color: $primary;
        cursor: pointer;
        & div {
            margin-left: 1rem;
        }
        & svg {
            fill: $primary;
        }

        &--active {
            color: #fff;
            background-color: var(--accent);
            & svg {
                fill: #fff;
            }
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        color: var(--accent);
        font-size: 1.3rem;
        font-weight: bold;
    }
}