@import "../../shared/styles/main.scss";


.order-reports {
    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        // margin-bottom: 2rem;
      }
    
      &__content {
      }

      &__form-wrapper {
        // background-color: #fff;
        // border-radius: 4px;
        // border: 1px solid $borderColor;
        &.custom-section {
          margin-bottom: 32px;
        }
        padding: 1.5rem;
      }
}