@import '../../../../shared/styles/main.scss';

.categories__subcategory-card { 
    &__wrapper {
        display: flex;
        margin-right: 0.7rem;
        margin-bottom: 0.7rem;
        padding: 0.5rem;
        background-color: #fff;
        min-width: 15rem;
        max-width: 15rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        // animation: fadeIn 0.4s ease-in;
    }

    &__shadow {
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        animation: fadeIn 0.3s;
        align-self: flex-start;
        & code {
            color: var(--accent);
        }
    }

    &__image {
        width: 100px;
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex:1;
    }
    &__footer {
        display: flex;
        justify-content: flex-end;
        & svg {
            fill: $primaryDark;
        }
    }

    &__content {
        width: 100%;
    }

    &__info {
        flex: 1;
        padding: 0.5rem;
    }
}