@import "../../../shared/styles/main.scss";
.pickers__add-edit-picker-modal {
  &__image-container {
    display: flex;
    position: relative;
    padding-bottom: 1.5rem;

    &__image {
      & img {
        width: 100%;
        max-height: 11rem;
      }

      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 1rem;
    }

    &__form {
      flex: 1;
    }
  }
}
