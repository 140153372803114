html {
  /* scrollbar jerk fix */
  /* margin-left: calc(100vw - 100%); */
}

body {
  margin: 0;
  background-color: #f8f8f8 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --accent: #2671E1;
  --accentBG: #2671E1b3;
  --primary: #6e7484;
  --mainFont: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  --alternateFont: "GeosansLight";
}
/* @font-face {
  font-family: "GeosansLight";
  src: url("./assets/fonts/GeosansLight.eot");
  src: local("GeosansLight"), local("GeosansLight"),
    url("./assets/fonts/GeosansLight.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/GeosansLight.woff2") format("woff2"),
    url("./assets/fonts/GeosansLight.woff") format("woff"),
    url("./assets/fonts/GeosansLight.ttf") format("truetype"),
    url("./assets/fonts/GeosansLight.svg#GeosansLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
} */
* {
  box-sizing: border-box;
  font-family: var(--mainFont);
}
.devButton {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999999999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.app-centered {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 10px 10px #fff; */
  border: 2px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* box-shadow: inset 0 0 10px 10px #ababab; */
  box-shadow: inset 0 0 10px 10px #ababab;
  border: 2px solid transparent;
  /* background-color: ; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #efefef;
}
