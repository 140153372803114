.auth-container {
    &__wrapper {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        // background-color: #2671E1;
//         /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f0f9ff+0,cbebff+47,a1dbff+100;Blue+3D+%2313 */
// background: #f0f9ff; /* Old browsers */
// background: -moz-linear-gradient(45deg,  #f0f9ff 0%, #cbebff 47%, #a1dbff 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(45deg,  #f0f9ff 0%,#cbebff 47%,#a1dbff 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(45deg,  #f0f9ff 0%,#cbebff 47%,#a1dbff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f9ff', endColorstr='#a1dbff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
background: transparent url('../assets/images/auth-background.png') center center no-repeat;
    }

    &__content-container {
        width: 80%;
        max-width: 650px;
    }
}