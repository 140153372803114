@import '../../shared/styles/main.scss';

.expired-promo {
    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    &__content {

    }

    &__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &--left {
            display: flex;
        }  

        &--right {
            & button {
                margin-right: 1rem;
            }

            & button:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__records-per-page {
        & label {
            color: $accent;
            font-weight: normal;
            margin-right: 0.5rem;
        }
    }

}