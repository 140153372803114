@import '../../shared/styles/main.scss';


.custom-navbar {
    &__wrapper {
        display: flex;
        flex-direction: row;
        // height: 4.5rem;
        background-color: $background;
        // position: fixed;
        z-index: 8;
        top: 0;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &--closed {
            margin-left: 80px;
            &[dir="rtl"] {
                margin-left: 0;
                margin-right: 80px;
            }
        }

        &--open {
            margin-left: 255.99px;
            &[dir="rtl"] {
                margin-left: 0;
                margin-right: 255.99px;
            }
        }
    }

    &__content-container {
        display: flex;
        align-items: center;
        padding: 1.1rem 1rem;
        color: $textPrimary;
        flex: 1;
        justify-content: space-between;
        background-color: #fff;
        box-shadow: 0 4px 24px 0 #22292f1a;
        margin: 18.2px 32px;
        // margin: 1.3rem 2rem 0;
        margin-bottom: 28px;
        // margin
        border-radius: 0.428rem;
        // position: fixed;
    }

    &__left-items-container {
        display: flex;
    }

    &__right-items-container {
        margin-right: 1rem;
        &[dir="rtl"] {
            margin-right: 0;margin-left: 1rem;
        }
        display: flex;
        align-items: center;
        &__profile-wrapper {
            display: flex;
            margin-right: 1rem;
            &[dir="rtl"] {
                margin-right: 0;margin-left: 1rem;
            }
        }

        &__avatar {
            & img{
                height: 50px;
                width: 50px;
            }
            // box-shadow: $shadowLow;
            margin-right: 0.5rem;
            border-radius: 100%;
            overflow: hidden;
            
        }

        &__profile-info {
            display: flex;
            flex-direction: column;
        }

        &__name {
            color: var(--accent);
            font-size: 1.1rem;
        }

        &__admin-type {
            color: $primaryDark;
        }
    }

    &__sidebar-toggle-button {
        & svg {
            fill: var(--accent);
        }
        display: flex;
        padding: 10px;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    border: 0;
    outline: none;
    }
}