@import '../../../../shared/styles/main.scss';
.categories-list {
    &__wrapper {
        display: flex;
        flex-direction: column;
        // margin-top: 24px;
    }
    &__count {
        // color: var(--accent);
        // padding: 1rem 0;
        font-size: 1rem;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem;
    }
}