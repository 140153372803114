@import "../../shared/styles/main.scss";
@import "../../shared/styles/colors.scss";

.driver {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  &__table {
    margin-top: 2rem;
    .ant-table-thead > tr > th {
      padding: 1.3rem;
      position: sticky;
      top: 0;
      background-color: $primary;
      color: #fff;
      font-size: 1rem;
      text-align: center !important;
      white-space: nowrap;
    }
    .ant-table-tbody {
      tr > td {
        padding: 1rem;
        text-align: center;
        color: $textPrimary;
        font-size: 0.9rem;
      }
      tr > td:first-child {
        border-left: 1px solid $secondary;
      }
      tr > td:last-child {
        border-right: 1px solid $secondary;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    flex-wrap: wrap;
    &--left {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
