@import "../../../../shared/styles/main.scss";


span.STRONKE {
    // -webkit-text-stroke:1px black;
    // -webkit-text-fill-color:transparent;
    // color: #;
    word-wrap: break-word !important;
    
}
.orders-table {
    &__wrapper {
        min-height: 400px;
        
        & table.custom-table {
            & th, td {
                padding: 1rem 0.4rem;
                font-size: 14px;
                overflow: hidden;
                white-space: unset;
            }

            & td {
                padding: 0.1rem;
                font-size: 13px;
                max-width: 100px;
            }


        }

    }


}

.menuWrappper {
    & i {
        margin-right: 0.4rem;
    }
}

.asap {
    position: relative;
    color: red;
    margin: 0;
    // top: 1rem;
}

.break {
    white-space: pre-wrap;
}