@import '../../shared/styles/main.scss';

.stores {
    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
    }

    &__records-per-page {
        margin-right: 1rem;
        & label {
            color: $accent;
            font-weight: normal;
            margin-right: 0.5rem;
        }
    }
}