@import '../../../../shared/styles/main.scss';

.custom-full-page-drawer {
    &__wrapper {

        & .ant-drawer-header {
            &>.ant-drawer-title{
                color: var(--accent);
            }
            padding: 0;
            &::after {
                content: "";
                width: 66px;
                height: 66px;
                clip-path: polygon(100% 0, 0 0, 100% 100%);
                background-color: var(--accent);
                right: 0;
                top: 0;
                position: absolute;
              }
            background-color: #f9f9f9;
        }

        & .ant-drawer-body {
            padding: 0;
        }
    }

    &__header {
        padding: 1.3rem;
    font-size: 1.4rem;
        display: flex;
        justify-content: space-between;

        & svg {
            top: 12px;
            right: 9px;
            z-index: 2;
            position: absolute;
            fill: #ffffff
        }
    }
}