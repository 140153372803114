
.wrapper {
    // width: 230px;
    height: 200px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #efefef;
    & img {
        height: 100%;
        width: 100%;
        &.hoverable {
            // &:hover {
            //     transform: scale(2);
            //     position: absolute;
            //     z-index: 999999999;
            // }
        }
    }
    & svg {
        fill: var(--primary);
    }

    &__table {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        & img {
            height: 100%;
            width: 100%;
        }
        background-color: #fff;
        & svg {
            fill: var(--primary);
            // width: 50px;
            // height: 50px;
        }
    }


}