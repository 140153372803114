@import '../../shared/styles/main.scss';

.login {
    &__wrapper {
        background-color: #fff;
        border-radius: 1rem;
        box-shadow: $shadowLow;
        display: flex;
        flex-direction: column;
    }
    &__container {
    }

    &__logo-image {
        // padding: 1rem;
        height: 100px;
    }

    &__company-name{
        font-family: monospace;
        font-style: normal;
        font-weight: 550;
        font-size: 50px;
        line-height: 40px;
        /* identical to box height, or 80% */

        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #252529;
    }

    &__logo-container {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        text-align: center;
        padding: 30px;
        background-color: #EBF2FC;
    }

    &__welcome-container {
        padding: 1rem;
        text-align: center;

        &__title {
            margin: 0;
            font-weight: normal;
            color: $primary;
            margin-bottom: 0.2rem;
            letter-spacing: 2px;
        }

        &__sub-title {
            margin: 0;
            font-weight: normal;
            color: $accent;
            letter-spacing: 1.25px;
        }
    }

    &__form-container {
        padding: 3rem 9rem;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__login-button {
        margin: 0 auto !important;
        margin-top: 1.7rem !important;
        width: 75%;
        background-color: #2671E1!important;
        border-color: #2671E1!important;
        max-width: 20rem;
    }

    &__form-item {
        padding-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        position: relative;

        &__password {
            position: relative;
            &>i {
                position: absolute;
                right: 5px;
            }
            & svg {
                fill: $primary;
            }
        }

        & input {
            border: 0;
            border-bottom: 1px solid $borderColor;
            border-radius: 0;

            &:focus {
                outline: none;
                box-shadow: 0;
                box-shadow: none;
                border-bottom: 1px solid $borderColor;
            }
        }
    }
}

@media only screen and (max-width: 786px) {
    .login {
        &__form-container {
            padding: 3rem 4rem;
        }
    }
  }