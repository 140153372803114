@import "../../shared/styles/main.scss";

.custom-sidebar {

  &__alternate-logo {
    & img {
      height: 70px;
      margin: 0 auto;
    }
  }

  &__alternate-H {
    position: absolute;
    right: 10px;
  }
  &__shadow-container {
    position: absolute;
    z-index: 2;
    display: none;
    height: 70px;
    top: 40px;
    pointer-events: none;
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-filter: blur(5px);
    filter: blur(5px);
    width: 80px;
    background: linear-gradient(
      #fff 41%,
      hsla(0, 0%, 100%, 0.11) 95%,
      hsla(0, 0%, 100%, 0)
    );
  }
  &__wrapper {
    width: 80px;
    background-color: #fff;
    // overflow: hidden;
    transition: background-color 0.3s, min-width 0.3s, max-width 0.3s,
      width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    & .custom-sidebar__logo-container {
      width: 80px;
      // height: 80px;
      & img {
          padding: 5px;
        // width: 50px;
        // height: 100%;
      }
    }
    overflow: hidden;

    &--sidebar-active {
      // & 
      & .custom-sidebar__content-container {
        // & section {
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: flex;
          // display: none;
          // opacity: 0;
          // border: 2px solid transparent;
        }
        // }
      }
     
      ::-webkit-scrollbar-thumb {
        // display: none;
        opacity: 1;
      }
      &:hover {
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          // opacity: 0;
          // background-color: #efefef;
          box-shadow: inset 0 0 10px 10px #efefef;
        }
      }
      & .custom-sidebar__shadow-container {
        width: 260px;
      }
      width: 260px;
      & .custom-sidebar__logo-container {
        width: 260px !important;
        & h1 {
          // visibility: visible;
          opacity: 1;
        }
      }
    }
    position: fixed;
    & .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      border-radius: 4px !important;
      margin: 0 15px;
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      &:hover {
        // transform: scale(1.08) translateX(5px);
      }
    }

    & .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
      background-color: #fff;
      border: none;
      &:hover {
        & svg {
          fill: #625f6e;
        }
      }
      & svg {
        fill: #625f6e;
      }
    }

    & .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
      background-color: #fff;
      color: #625f6e;

      & .ant-menu-submenu-title {
        background-color: #f5f5f5 !important;
        &:hover {
          transform: none;
        }
      }
      & svg {
        fill: #625f6e !important;
      }
    }

    // position: fixed;
    box-shadow: 0 0 15px 0 #22292f0d;
    z-index: 9;

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      // opacity: 0;

      // margin: 3px;
      box-shadow: inset 0 0 10px 10px #fff;
      border: 2px solid transparent;
      background-color: #fff;
    }

    ::-webkit-scrollbar-track {
      background-color: #fff;
      box-shadow: inset 0 0 10px 10px #fff;
      border: 2px solid transparent;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    position: fixed;
    background-color: #EBF2FC;
    // background-color: #2671E1;
    // background: linear-gradient(118deg, var(--accent), var(--accentBG));
    z-index: 999;
    top: 0;
    // box-shadow: 0 4px 2px -2px #fff;
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0;
    & h1 {
      display: flex;
      // flex: 1;
      align-items: center;
      // margin: 0 auto;
      // visibility: hidden;
      margin: 0;
      opacity: 0;
      justify-content: space-between;
      padding: 0 5px;
      padding-right: 0;
      &[dir="rtl"] {
        padding-left: 0;
        padding-right: 5px;
      }
      white-space: nowrap;
      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      // color: #000;
      & span {
        max-width: 140px;
        overflow: hidden;
        // font-size: 10.5px;
      }
      font-size: 18px;
    }
    &__left-logo {
      // width: 45px;
      // height: 100%;
      // width: 100%;
      // max-width: 50px;
      // max-height: 100px;
      // max-height: 60px;
      height: 80px;
      width: 80px;
      margin-left: 1px;
      object-fit: contain;
      object-position: center;
      // margin-left: 0.5rem;
      flex-shrink: 0;
      // & img {
      //   object-fit: cover;
      // }
    }

    &__right-logo {
      width: 155px;
      // display: none;
      flex-shrink: 0;
    }
  }

  &__content-container {
    & section {
      // margin-top: 64px;
    }
    width: inherit;
    position: sticky;
    top: 0;
    // margin-top: 64px;
    margin-top: 80px;
    padding-top: 11px;
    height: calc(100vh - 70px);
    padding-bottom: 70px;
    overflow-x: unset;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    // white-space: pre-wrap;
    // scrollbar-width: none;
  }

  &__list-item {
    &:hover {
      // transform: scale(1.03);
      // transition: all 0.1s;
      // color: var(--accentBG);

      & .custom-sidebar__list-item__text {
        color: var(--accentBG);
        // left: 13px;
        // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      & .custom-sidebar__list-item__icon {
        & svg {
          fill: var(--accentBG);
        }
        // left: 13px;
        // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    // padding: 1.1rem 1.5rem;
    & .custom-sidebar__list-item__icon {
      // left: 13px;
      // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &.custom-sidebar__list-item--toggled {
      background: #f5f5f5;
      box-shadow: none;
      color: #625f6e;

      & svg {
        fill: #625f6e;
      }

      &:hover {
        color: #625f6e;
        & .custom-sidebar__list-item__text {
          color: var(--accentBG);
          // left: 13px;
          // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
  
        & .custom-sidebar__list-item__icon {
          & svg {
            fill: var(--accentBG);
          }
          // left: 13px;
          // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }
    &--closed {
      &:hover {
        transform: scale(1.08) translateX(5px);
      }
      &.custom-sidebar__list-item--active {
        background: #f5f5f5;
        box-shadow: none;
        & svg {
          fill: #625f6e;
        }
      }
      & .custom-sidebar__list-item__icon {
        // left: 13px;
        // animation: ltr 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
      }

      & .custom-sidebar__list-item__text {
        // display: none;
        opacity: 0;
        // transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        animation: fadeOut 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
        animation-fill-mode: forwards;
      }
      padding: 0 16px;
    }
    white-space: nowrap;
    height: 45px;
    display: flex;
    line-height: 1.45;
    align-items: center;
    padding: 12px 15px 12px 15px;
    margin: 3px 15px;
    font-weight: 400;

    // margin-left: 25px;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
    border-radius: 4px;
    // margin-right: 25px;
    font-size: 16px;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    position: relative;
    color: #625f6e;
    // color: hsla(0, 0%, 100%, 0.65);
    // border-right: 2.5px solid #fff;
    & svg {
      fill: #625f6e;
    }
    &:hover {
      color: #625f6e;
      // background-color: $highlight;
      & svg {
        // fill: #fff;
      }
    }

    &__icon {
      // padding-right: 1.2rem;
      // padding-left: 9px;
      display: flex;
      // position: relative;
      // justify-content: center;
      &--submenu {
        // padding-right: 1.6rem;
        // padding-left: 1.33rem;
        // padding: 0 16px;
        padding-right: 0;
        &[dir="rtl"] {
          padding-left: 0;
        }
        display: flex;
      }
    }

    &__text {
      opacity: 1;
      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      padding: 0 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0;
      &[dir="rtl"] {
        padding-left: 0;
        padding-right: 1.2rem;
      }
    }

    &--active {
      color: #fff;
      transform: none !important;
      // border-right: 2.5px solid;
      // background-color: var(--accent);

      background: linear-gradient(118deg, var(--accent), var(--accentBG));
    //   box-shadow: 0 0 10px 1px (var(--accentBG));
      &:hover {
        & .custom-sidebar__list-item__text {
          color: #Fff;
          // left: 13px;
          // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
  
        & .custom-sidebar__list-item__icon {
            & svg {
          fill: #fff;
        }
      }
      }

      & svg {
        fill: #fff;
      }
    }
  }

  &__sub-menu-item {
    &:hover {
      // transform: scale(1.03);
      // transition: all 0.1s;
      & .custom-sidebar__list-item__text {
        color: var(--accentBG);
        // left: 13px;
        // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      & .custom-sidebar__list-item__icon--submenu {
        & svg {
          fill: var(--accentBG);
        }
        // left: 13px;
        // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    // padding: 1.1rem 1.5rem;
    & .custom-sidebar__list-item__icon {
      // left: 13px;
      // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &--closed {
      &:hover {
        // transform: scale(1.08) translateX(5px);
      }
      &.custom-sidebar__list-item--active {
        background: #f5f5f5;
        box-shadow: none;
        color: #fff !important;
        & svg {
          fill: #625f6e;
        }
      }
      & .custom-sidebar__list-item__icon {
        // left: 13px;
        // animation: ltr 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
      }

      & .custom-sidebar__list-item__text {
        // display: none;
        opacity: 0;
        // animation: fadeOut 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
        // animation-fill-mode: forwards;
      }
      padding: 0 16px;
    }
    white-space: nowrap;
    height: 45px;
    display: flex;
    // line-height: 1.45;
    align-items: center;
    padding: 10px 15px 10px 15px;
    margin: 0 15px;
    // margin-left: 45px;
    font-weight: 400;

    // margin-left: 25px;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
    border-radius: 4px;
    // margin-right: 25px;
    font-size: 14px;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    position: relative;
    color: #625f6e;
    padding-left: 30px;
    &[dir="rtl"] {
      padding-left: 15px;
      padding-right: 30px;
    }
    // color: hsla(0, 0%, 100%, 0.65);
    // border-right: 2.5px solid #fff;
    & svg {
      fill: #625f6e;
    }
    &:hover {
      color: #625f6e;
      // background-color: $highlight;
      & svg {
        // fill: #fff;
      }
    }

    &__icon {
      // padding-right: 1.2rem;
      // padding-left: 9px;
      display: flex;
      // position: relative;
      // justify-content: center;
      &--submenu {
        // padding-right: 1.6rem;
        // padding-left: 1.33rem;
        padding: 0 16px;
        padding-right: 0;
        display: flex;
      }
    }

    &__text {
      opacity: 1;
      padding: 0 1.2rem;
    }

    &--active {
      color: #fff;
      transform: none !important;
      // border-right: 2.5px solid;
      // background-color: var(--accent);
      background: linear-gradient(118deg, var(--accent), var(--accentBG));
      box-shadow: 0 0 10px 1px (var(--accentBG));
      &:hover {
        & .custom-sidebar__list-item__text {
          color: #Fff;
          // left: 13px;
          // animation: rtl 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
  
        & .custom-sidebar__list-item__icon--submenu {
            & svg {
          fill: #fff;
        }
      }
      }

      & svg {
        fill: #fff;
      }
    }
  }

  &__drop-icon {
    position: absolute;
    display: flex;
    align-items: center;
    top: 16px;
    right: 16px;
    &[dir="rtl"] {
      right: 90%;
      // left: 0;
    }
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    &--rotated {
      transform: scaleY(-1);
    }
  }

  &__sub-menu-container {
    max-height: 0;
    // background-color: #f2f2f2;
    // border-color: #f2f2f2;
    // margin:0 15px;
    transition: padding 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
      max-height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;

    &--toggled {
      animation: collapse 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
      // margin: 0.7rem 0;
      padding: 0.5rem 0;
      // padding-top: 1rem;
      // background-color: #f5f5f5;
      max-height: 500px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    position: fixed;
    background-color: #fff;
    bottom: -1px;
    padding: 16px;
    // padding: 10px 0;
    padding-left: 31px;
    cursor: pointer;
    & svg {
      fill: #625f6e;
    }
    &:hover {
      & svg {
        fill: var(--accent);
      }
    }
    border-top: 1px solid #e8e8e8;

    width: 80px;
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &--active {
      width: 260px;
    }
  }
}
// @keyframes ltr {
//   from {
//     left: 15px;
//   }
//   to {
//     left: 0px;
//   }
// }
@keyframes collapse {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
