
@import "../../../shared/styles/main.scss";
.custom-placeholder {
    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
        margin-bottom: 2rem;
        & svg {
            fill: $primaryDark;
        }
    }
}