@import "../../shared/styles/main.scss";

.admin-settings {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  &__content {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $borderColor;
    padding: 1rem;
  }

  &__form-header {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  &__form-wrapper {
    margin-bottom: 1rem;
  }
  &__form-wrapper .custom-form-item {
    max-width: 20rem;
  }

  &__content {
  }
}
