

.order-reports__chart__legend {
    &__wrapper {
        align-items: center;
    display: flex;
    }

    &__color {
        padding: 0.5rem;
        margin: 0 0.3rem;
        height: 10px;
        width: 10px;
        border-radius: 2px;
    }

    &__label {
        // margin: 0.5rem
        white-space: nowrap;
    }

    &__value {
        white-space: nowrap;
        // margin: 0.5rem
    }
}