.span-img{
    color: #ff0000;
}

.geofence-add-form-wrapper {
    padding: 32px;
    // padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .custom-form-group {
        flex: 2;
    }
    & .custom-form-item {
        // padding-b
    }
    section {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}